.contents-container {
    background-color: #242424;
    height: 90vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;
}

.contents-container > h1 {
    color: white;
    font-size: 100px;
    margin-top: -100px;
    font-family: "JetBrains Mono", serif;
}

.contents-container > h3 {
    margin-top: 8px;
    color: white;
    font-size: 32px;
    font-family: "JetBrains Mono", serif;
}
.contents-container > h4 {
    margin-top: 8px;
    color: white;
    font-size: 50px;
    font-family: 'PT Sans', sans-serif ;
}

@media screen and (max-width: 960px){
    .contents-container > h1 {
        font-size: 70px;
        margin-top: -150px;
    }
}

@media screen and (max-width: 768px){
    .contents-container > h1 {
        font-size: 50px;
        margin-top: -100px;
    }
    .contents-container >p {
        font-size: 30px;
    }
    .contents-container>h4 {
        font-size: 40px;
    }
}