@import url("https://fonts.googleapis.com/css?family=Sacramento&display=swap");

.contents-text > h2 {
    color: #7c795d;
    font-family: 'Libre Baskerville', serif;
    font-size: 60px;
    font-weight: 600;
    margin-bottom: 10px;
    padding: 1rem;
    line-height: 1;

}
.contents-text > h3 {
    color: #252e48;
    font-family: 'Libre Baskerville', serif;
    font-size: 40px;
    font-weight: 600;
    padding: 1rem;
    line-height: 1;
    margin-top: 1%;

}
.txt {
    margin-left: 10%;
    margin-right: 10%;
    font-family: 'Trocchi', sans-serif;
}
.contents-text > h4 {
    color: #252e48;
    font-family: 'Trocchi', sans-serif;
    font-size: 40px;
    font-weight: 600;
    margin: 0 0 0;
    padding: 1rem;
    line-height: 1;

}
.contents-text > h5 {
    color: brown;
    font-family: 'Trocchi', sans-serif;
    font-size: 20px;
    font-weight: 600;
    padding: 1rem;
    line-height: 1;
    margin: 0 0 50px;

}
.sub-contents-text {
    color: #7c795d;
    font-family: 'Libre Baskerville', serif;
    font-size: 30px;
    font-weight: 600;
    margin: 0 0 20px;
    padding: 3rem;
    line-height: 1.2;

}
.cards-wrapper {
    position: relative;
    margin: 50px 0 45px;
}

.contents-text > p {
    color: #4c4a37;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    line-height: 32px;
    margin: 0 0 20px;
}

.contents-text {
    text-align: center;
}

#awardee {
    vertical-align: central;
    position: relative;
    display: inline-block;
    width: 20%;
    background: none; }

@media screen and (max-width: 800px) {
    #awardee {
        vertical-align: central;
        position: relative;
        display: compact;
        width: 30%;
        background: none;
    }
    .contents-text > h3 {
        margin-top: 25%;

    }
}
.column {
    float: left;
    width: 25%;
    height: 200px;
    margin-bottom: 100px;
    padding: 0 50px;
}
@media screen and (max-width: 1200px) {
    .column {
        width: 50%;
        display: block;
        margin-bottom: 300px;
    }

    .row {
        margin-bottom: 0;
    }
}


.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    image-resolution: 200x;
}

/* Some left and right padding inside the container */
.container {
    padding: 0 10px;
}
.container > p {
    margin-bottom: 30px;
    color: grey;
}
.container >h2 {
    color: brown;
}

/* Clear floats */
.container::after, .row::after {
    content: "";
    clear: both;
    display: table;
    margin-bottom: 200px;
}
.cards-item-pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 100%;
}

.title {
    color: grey;
    padding: 10px;
}

.button {
    border: none;
    outline: 0;
    display: inline-block;
    padding: 8px;
    color: white;
    background-color: #000;
    text-align: center;
    cursor: pointer;
    width: 100%;
}

.button:hover {
    background-color: #555;
}
.custom-btn {
    width: 130px;
    height: 40px;
    color: #fff;
    border-radius: 5px;
    padding: 10px 25px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    box-shadow:inset 2px 2px 2px 0 rgba(255,255,255,.5),
    7px 7px 20px 0 rgba(0,0,0,.1),
    4px 4px 5px 0 rgba(0,0,0,.1);
    outline: none;
}
.btn-12{
    position: relative;
    right: 20px;
    bottom: 20px;
    border:none;
    box-shadow: none;
    width: 130px;
    height: 40px;
    line-height: 42px;
    -webkit-perspective: 230px;
    perspective: 230px;
}
.btn-12 span {
    background: rgb(0,172,238);
    background: linear-gradient(0deg, rgba(0,172,238,1) 0%, rgba(2,126,251,1) 100%);
    display: block;
    position: absolute;
    width: 130px;
    height: 40px;
    box-shadow:inset 2px 2px 2px 0 rgba(255,255,255,.5),
    7px 7px 20px 0 rgba(0,0,0,.1),
    4px 4px 5px 0 rgba(0,0,0,.1);
    border-radius: 5px;
    margin:0;
    text-align: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all .3s;
    transition: all .3s;
}
.btn-12 span:nth-child(1) {
    box-shadow:
            -7px -7px 20px 0 #fff9,
            -4px -4px 5px 0 #fff9,
            7px 7px 20px 0 #0002,
            4px 4px 5px 0 #0001;
    -webkit-transform: rotateX(90deg);
    -moz-transform: rotateX(90deg);
    transform: rotateX(90deg);
    -webkit-transform-origin: 50% 50% -20px;
    -moz-transform-origin: 50% 50% -20px;
    transform-origin: 50% 50% -20px;
}
.btn-12 span:nth-child(2) {
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: 50% 50% -20px;
    -moz-transform-origin: 50% 50% -20px;
    transform-origin: 50% 50% -20px;
}
.btn-12:hover span:nth-child(1) {
    box-shadow:inset 2px 2px 2px 0 rgba(255,255,255,.5),
    7px 7px 20px 0 rgba(0,0,0,.1),
    4px 4px 5px 0 rgba(0,0,0,.1);
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    transform: rotateX(0deg);
}
.btn-12:hover span:nth-child(2) {
    box-shadow:inset 2px 2px 2px 0 rgba(255,255,255,.5),
    7px 7px 20px 0 rgba(0,0,0,.1),
    4px 4px 5px 0 rgba(0,0,0,.1);
    color: transparent;
    -webkit-transform: rotateX(-90deg);
    -moz-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
}
.wrapper {
    display: grid;
    grid-template-columns: 60% 40%;
    width: 100%;
}
   .box {
       background-color: #444;
       color: #fff;
       width: 100%;

   }

* {
    box-sizing: border-box;
}
.a {
    background-color: white;
    color: #7c795e;
    font-family: 'Source Sans Pro', sans-serif;
    text-align: left;
    padding-left: 10%;
    padding-top: 2%;

}
.b {
    background-color: white;
    color: midnightblue;
    line-height: 180%;
    font-family: 'Source Sans Pro', sans-serif;
    padding-right: 5%;

}

.container {
    background-color: white;
    height: 100px;
    width: 100%;
    margin-bottom: 5%;
}
@media screen and (max-width: 960px)
{
    .container {
        margin-bottom: 30%;
    }

}



