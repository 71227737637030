.cards {
    padding: 0;
    background: #fff;
}
h1 {  }


.heading-texts > h1 { color: #7c795d; font-family: 'Libre Baskerville', serif; font-size: 50px; font-weight: 600; line-height: 32px; margin: 0 0 20px; line-height: 1 }

.heading-texts > p { color: #4c4a37; font-family: 'Source Sans Pro', sans-serif; font-size: 18px; line-height: 32px; margin: 0 0 30px; }

.heading-texts {
    text-align: center;
    padding: 1rem;
}

.im {
    height: 1000px;
}



h1 {
    text-align: center;
    padding-top: 50px;
}


.cards-container {
    display: compact;
    text-align: center;
    flex-flow: column;
    align-items: center;
    max-width: 2120px;
    width: 70%;
    margin: 0 auto;
}

.cards-container2 {
    display: compact;
    text-align: center;
    flex-flow: column;
    alignment: center;
    max-width: 2120px;
    width: 100%;
}

.cards-wrapper {
    position: relative;
    margin: 10px 0 15px;
}

.cards-items {
    margin-bottom: 24px;
}

.cards-item {
    display: flex;
    flex: 1;
    margin: 0 1rem;
    border-radius: 0px;
}

.cards-item-link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
}

.cards-item-pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 100%;
}

.fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
}

.cards-item-pic-wrap::after {
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
}

.cards-item-img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: fill;
    transition: all 0.2s linear;
    margin-bottom: 20px;

}

.cards-item-img:hover {
    transform: scale(0.95);
}

.cards-item-info {
    padding: 5px 10px 30px;
}

.cards-item-text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
}

.cards-item-prof {
    color: darkgrey;
    font-size: 18px;
    line-height: 24px;
}
.cards-item-place {
    color: brown;
    font-size: 18px;
    line-height: 24px;
}

@media only screen and (min-width: 1200px) {
    .content-blog-container {
        width: 84%;
    }
}

@media only screen and (min-width: 1024px) {
    .cards-items {
        display: flex;
    }
}

@media only screen and (max-width: 1024px) {
    .cards-item {
        margin-bottom: 2rem;
    }
}
.center {
    display: block;
    width: 100%;
    height: 100%;
}